import { IconComponent } from "@/ui/types";

export const Coin: IconComponent = (props) => {
	return (
		<svg {...props} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M4.20004 2.33398H9.80004V3.26732H4.20004V2.33398ZM3.26671 4.20065V3.26732H4.20004V4.20065H3.26671ZM3.26671 9.80065V4.20065H2.33337V9.80065H3.26671ZM4.20004 10.734V9.80065H3.26671V10.734H4.20004ZM9.80004 10.734V11.6673H4.20004V10.734H9.80004ZM10.7334 9.80065V10.734H9.80004V9.80065H10.7334ZM10.7334 4.20065H11.6667V9.80065H10.7334V4.20065ZM10.7334 4.20065V3.26732H9.80004V4.20065H10.7334ZM6.53337 3.73398H7.46671V4.66732H8.86671V5.60065H6.06671V6.53398H8.86671V9.33398H7.46671V10.2673H6.53337V9.33398H5.13337V8.40065H7.93337V7.46732H5.13337V4.66732H6.53337V3.73398Z"
				fill="currentColor"
			/>
		</svg>
	);
};
