// import { gtagEvent } from '@sushiswap/ui'

import { coinbaseWallet, injected, safe, walletConnect } from "@wagmi/connectors";
import { http, cookieStorage, createConfig, createStorage } from "wagmi";
// import { Writeable } from "zod";
import { publicChains, publicTransports, ChainId } from "../chains/constants";
import { mainnet } from "wagmi/chains";

export const publicWagmiConfig = {
	chains: [...publicChains, mainnet],
	transports: { ...publicTransports, 1: http(mainnet.rpcUrls.default.http[0]) },
	batch: {
		multicall: {
			wait: 64,
		},
	},
} as const satisfies Parameters<typeof createConfig>[0];

export type PublicWagmiConfig = ReturnType<
	typeof createConfig<(typeof publicWagmiConfig)["chains"], (typeof publicWagmiConfig)["transports"]>
>;

export const DEFAULT_POLLING_INTERVAL = 4_000;

// Allow for custom polling intervals for each chain with a default
const pollingInterval = new Proxy(
	{
		[ChainId.CURTIS]: 3000,
	} as Partial<Record<ChainId, number>>,
	{
		get: (target, name) => {
			return Object.hasOwn(target, name)
				? target[Number(name) as keyof typeof target]
				: DEFAULT_POLLING_INTERVAL;
		},
	}
);

export const createProductionConfig = () => {
	const transports = publicWagmiConfig.transports;
	// const transports = Object.entries(publicTransports).reduce((acc, [chainId, transport]) => {
	// 	const transportUrl = transport({ chain: undefined }).value?.url!;

	// 	acc[Number(chainId) as ChainId] = http(transportUrl, {
	// 		onFetchResponse(_res) {
	// 			if (typeof window !== "undefined" && transportUrl.includes("drpc")) {
	// 				let fallback = "undefined";
	// 				if ("useSwapApi" in window && typeof window?.useSwapApi !== "undefined") {
	// 					fallback = window?.useSwapApi ? "true" : "false";
	// 				}

	// 				// gtagEvent('drpc-response', {
	// 				//   pathname: window.location.pathname,
	// 				//   href: window.location.href,
	// 				//   fallback,
	// 				//   chainId,
	// 				// })
	// 			}
	// 		},
	// 	});
	// 	return acc;
	// }, {} as Writeable<typeof publicTransports>);

	return createConfig({
		...publicWagmiConfig,
		transports,
		pollingInterval,
		connectors: [
			injected({
				shimDisconnect: true,
			}),
			walletConnect({
				showQrModal: true,
				projectId: "b7253617ac057b19b0886cd63a77d921",
				metadata: {
					name: "Saru",
					description: "Swap, earn, and build on the leading decentralized crypto trading protocol.",
					url: "https://www.saru.com",
					icons: ["https://www.saru.com/icon.png"],
				},
			}),
			coinbaseWallet({
				// TODO: Flesh out coinbase wallet connect options?
				appName: "Saru",
				appLogoUrl:
					"https://raw.githubusercontent.com/sushiswap/list/master/logos/token-logos/token/sushi.jpg",
			}),
			safe({
				// TODO: Other self-hosted safes for some networks?
				allowedDomains: [
					/gnosis-safe.io$/,
					/app.safe.global$/,
					/safe.fuse.io$/,
					/multisig.moonbeam.network$/,
					/safe.fantom.network$/,
					/ui.celo-safe.io$/,
					/multisig.harmony.one$/,
				],
				debug: false,
			}),
		],
		storage: createStorage({
			storage: cookieStorage,
		}),
		ssr: true,
	});
};

export const config = createProductionConfig();
