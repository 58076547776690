import { IconComponent } from "@/ui/types";

export const Discord: IconComponent = (props) => {
	return (
		<svg {...props} viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M4.01229 10.005V10.9693H4.99693V12H1.97475V11.0575H0.987373V10.0514H0V3.98453H0.957343V1.99864C1.29223 1.97953 1.5889 1.96225 1.93744 1.94223C1.95291 1.63111 1.96747 1.32363 1.98476 0.97612H2.94301V0.00454856H6.01251V0.940641H9.95109C9.96565 0.632249 9.9793 0.334774 9.99386 0H13.0142V0.937002C13.36 0.954287 13.6685 0.969752 14.0189 0.987037V1.9395C14.3665 1.9577 14.6759 1.97316 15.0272 1.99136V3.94178H16V10.0096C15.7188 10.0223 15.4103 10.0368 15.0581 10.0532V11.0057C14.7105 11.0239 14.402 11.0403 14.0507 11.0584V12H11.0049V11.0011H11.9923C11.9923 10.6336 11.9923 10.3216 11.9923 10.0086C12.3153 9.98863 12.6384 9.96952 12.9451 9.95042V9.0589H12.0514C12.0359 9.39732 12.0214 9.71026 12.0068 10.0241C9.33773 10.0223 6.66773 10.0214 3.99863 10.0196C3.9777 9.6957 3.95677 9.37093 3.93675 9.06527H3.05039V9.95588C3.38801 9.97316 3.70106 9.98954 4.01411 10.005H4.01229ZM3.05767 5.04799V6.98931H4.02685V7.93814H5.98794V6.96657H6.93345V5.00887H5.97065V4.05822H4.00774V5.00432C3.66739 5.01979 3.37072 5.03343 3.05767 5.04799ZM10.0193 4.04367V5.00432C9.66807 5.02161 9.36048 5.03707 9.0629 5.05254V6.95292C9.39051 6.96839 9.68627 6.98112 10.0412 6.9975V7.94451H11.9877V6.9975C12.3381 6.98112 12.6447 6.96566 12.9423 6.95201V5.01524H11.9631V4.04367H10.0193Z"
				fill="currentColor"
			/>
		</svg>
	);
};
