export const getBlockExplorer = (chainId: number) => {
	switch (chainId) {
		case 33111:
			return "https://curtis.explorer.caldera.xyz";
		default:
			throw new Error("Unsupported chain");
	}
};

export const getBlockExplorerForAddress = (chainId: number, address: string) => {
	const baseUrl = getBlockExplorer(chainId);

	return `${baseUrl}/address/${address}`;
};
