import { useTheme } from "next-themes";
import { Moon } from "../components/icons/moon";
import { Sun } from "../components/icons/sun";
import { FC } from "react";
import { cn } from "@/lib/utils";

export const Theme = () => {
	const { theme, setTheme } = useTheme();

	return (
		<div className="flex-col flex gap-1  w-full">
			<h6 className="text-sm font-bold text-primary">Theme</h6>
			<div className="flex items-center w-full">
				<ThemeButton
					onClick={() => {
						setTheme("dark");
					}}
					isActive={theme === "dark"}>
					<Moon width={16} height={16} className="mr-2" />
					Dark
				</ThemeButton>
				<ThemeButton onClick={() => setTheme("light")} isActive={theme === "light"}>
					<Sun width={16} height={16} className="mr-2" />
					Light
				</ThemeButton>
			</div>
		</div>
	);
};

interface ThemeButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	isActive: boolean;
}

const ThemeButton: FC<ThemeButton> = ({ children, className, isActive, ...props }) => {
	return (
		<button
			className={cn(
				"flex items-center px-4 w-full py-1.5",
				isActive ? "bg-accent text-[#FAFAFA]" : "text-secondary bg-dialog-content",
				className
			)}
			{...props}>
			{children}
		</button>
	);
};
