import { Button } from "../components/button";
import { Popover, PopoverContent, PopoverPrimitive, PopoverTrigger } from "../components/popover";
import { Settings as SettingsIcon } from "../components/icons/settings";
import { Close } from "../components/icons/close";
import {
	Dialog,
	DialogBody,
	DialogClose,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "../components/dialog";
import { Theme } from "./theme";
import { SlippageTolerance } from "./slippage-tolerance";
import { Divider } from "../components/divider";
import { MobileSettingsIcon } from "../components/icons/mobile-settings-icon";
import { LinkExternal, LinkInternal } from "../components/link";
import { X } from "../components/icons/x";
import { GitHub } from "../components/icons/github";
import { Discord } from "../components/icons/discord";
import { PoweredBy } from "../components/powered-by";

export const Settings = () => {
	return (
		<>
			<Popover>
				<PopoverTrigger asChild className="hidden md:flex">
					<Button variant={"settings"} className="max-w-[40px]">
						<div className="text-primary">
							<SettingsIcon width={16} height={16} />
						</div>
					</Button>
				</PopoverTrigger>

				<PopoverContent align={"end"} className="flex-col  hidden md:flex w-[348px]">
					<div className="flex justify-between items-center p-2">
						<div>Settings</div>
						<PopoverPrimitive.Close asChild>
							<Close width={14} height={14} />
						</PopoverPrimitive.Close>
					</div>
					<section className="bg-dialog-body p-4 flex flex-col gap-2">
						<_Settings />
					</section>
				</PopoverContent>
			</Popover>
			<Dialog>
				<DialogTrigger asChild>
					<Button variant={"settings"} size={"sm"} className="max-w-[40px] flex md:hidden">
						<div className="text-primary">
							<MobileSettingsIcon width={20} height={20} />
						</div>
					</Button>
				</DialogTrigger>

				<DialogContent
					overlayClassName="md:hidden"
					aria-describedby={undefined}
					className="block md:hidden pb-12">
					<DialogHeader className="py-2 px-2">
						<div className="flex justify-between items-center">
							<DialogTitle>Settings</DialogTitle>
							<DialogClose asChild>
								<Button variant="ghost" size={"sm"}>
									<Close width={16} height={16} />
								</Button>
							</DialogClose>
						</div>
					</DialogHeader>
					<DialogBody className="flex flex-col gap-2 max-h-[450px] overflow-y-auto overflow-x-hidden">
						<_Settings />
						<Divider />
						<section className="flex justify-between items-center w-full">
							<div className="flex flex-col gap-2">
								<LinkInternal href="/terms-of-use" className="text-accent">
									Terms of Use
								</LinkInternal>
								<LinkInternal href="#" className="text-accent">
									Privacy Policy
								</LinkInternal>
							</div>
							<div className="flex gap-2 items-center">
								<LinkExternal href={"https://x.com/saru_ex"}>
									<Button variant={"always-dark"} size={"sm"}>
										<X width={16} height={16} />
									</Button>
								</LinkExternal>
								<LinkExternal href={"https://github.com/sushi-labs/saru"}>
									<Button variant={"always-dark"} size={"sm"}>
										<GitHub width={16} height={16} />
									</Button>
								</LinkExternal>
								<LinkExternal href={"https://discord.com/invite/UevqsDRzb7"}>
									<Button variant={"always-dark"} size={"sm"}>
										<Discord width={16} height={16} />
									</Button>
								</LinkExternal>
							</div>
						</section>
						<Divider />
						<section className="mt-2">
							<PoweredBy className="text-sm" />
						</section>
					</DialogBody>
				</DialogContent>
			</Dialog>
		</>
	);
};

const _Settings = () => {
	return (
		<>
			<Theme />
			<Divider />
			<SlippageTolerance
				options={{
					defaultValue: "AUTO",
				}}
			/>
		</>
	);
};
