"use client";

import { wagmiConfig } from "@/lib/wagmi";
import { State, WagmiProvider } from "wagmi";
import { type FC, type ReactNode } from "react";

export const WagmiConfig: FC<{
	children: ReactNode;
	initialState: State | undefined;
}> = ({ children, initialState }) => {
	return (
		<WagmiProvider config={wagmiConfig} initialState={initialState}>
			{children}
		</WagmiProvider>
	);
};
