import { IconComponent } from "@/ui/types";

export const SaruIcon: IconComponent = (props) => {
	return (
		<svg {...props} viewBox="0 0 62 51" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M59.205 22.1809V19.0116H52.6489V13.4425H50.4643V11.2604H48.2786V9.07469H46.0929V6.89006H43.9072V4.70435H18.0927V6.89006H15.907V9.07469H13.7224V11.2604H11.5367V13.4425H9.35097V19.0116H2.79476V22.1809H0.608887V33.1121H2.7946V36.2814H9.35066V41.6685H11.5364V43.8543H13.7221V46.04H15.9067V48.2257H18.0924V50.4103H43.9067V48.2257H46.0924V46.04H48.2781V43.8543H50.4638V41.6685H52.6485V36.2814H59.2045V33.1121H61.3902V22.1809H59.205ZM9.35081 32.6388H5.96749V30.1855H4.27535V25.1076H5.96764V22.6542H9.35097L9.35081 32.6388ZM48.077 28.7989H45.8924V30.9846H43.7067V33.1703H40.9335V40.4911H38.7478V42.6757H36.562V44.8614H25.8199V42.6757H23.6342V40.4911H21.4485V33.1703H18.2946V30.9846H16.1089V28.7989H13.9232V18.9593H16.1089V16.7736H18.2946V14.5879H43.7068V16.7736H45.8926V18.9593H48.0772V28.7989H48.077ZM57.7244 30.1855H56.0321V32.639H52.6488V22.6543H56.0321V25.1077H57.7244V30.1855Z"
				fill="currentColor"
			/>
			<path d="M21.5699 23.8735H19.1641V26.2794H21.5699V23.8735Z" fill="currentColor" />
			<path d="M22.773 22.4578H20.3672V24.8636H22.773V22.4578Z" fill="currentColor" />
			<path d="M25.0235 20.9587H21.5698V23.3646H25.0235V20.9587Z" fill="currentColor" />
			<path d="M26.3814 20.9587H23.9756V23.3646H26.3814V20.9587Z" fill="currentColor" />
			<path d="M27.5845 22.4578H25.1787V24.8636H27.5845V22.4578Z" fill="currentColor" />
			<path d="M28.7872 23.8735H26.3813V26.2794H28.7872V23.8735Z" fill="currentColor" />
			<path d="M35.6187 23.8735H33.2129V26.2794H35.6187V23.8735Z" fill="currentColor" />
			<path d="M36.8214 22.4578H34.4155V24.8636H36.8214V22.4578Z" fill="currentColor" />
			<path d="M38.8631 20.9587H35.6187V23.3646H38.8631V20.9587Z" fill="currentColor" />
			<path d="M40.4302 20.9587H38.0244V23.3646H40.4302V20.9587Z" fill="currentColor" />
			<path d="M41.6334 22.4578H39.2275V24.8636H41.6334V22.4578Z" fill="currentColor" />
			<path d="M42.836 23.8735H40.4302V26.2794H42.836V23.8735Z" fill="currentColor" />
			<path d="M29.8622 29.8896H27.6768V32.0751H29.8622V29.8896Z" fill="currentColor" />
			<path d="M34.3231 29.8896H32.1377V32.0751H34.3231V29.8896Z" fill="currentColor" />
			<path d="M35.6407 3.17212H26.3589V5.35752H35.6407V3.17212Z" fill="currentColor" />
			<path d="M34.0705 1.78955H28.1406V3.97496H34.0705V1.78955Z" fill="currentColor" />
			<path d="M32.0921 0.371582H29.9067V2.55699H32.0921V0.371582Z" fill="currentColor" />
		</svg>
	);
};
