import { IconComponent } from "@/ui/types";

export const Coins: IconComponent = (props) => {
	return (
		<svg {...props} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M11.2663 4L17.9329 4V5.33333L11.2663 5.33333V4Z" fill="currentColor" />
			<path d="M9.93294 6.66667V5.33333H11.2663V6.66667H9.93294Z" fill="currentColor" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.93294 8V6.66667H8.59961V8H7.26628V9.33333H5.93294V10.6667H4.59961L4.59961 17.3333H5.93294V18.6667H7.26628V20H13.9329V18.6667H15.2663L15.2663 17.3333H16.5996V16H17.9329V14.6667L19.2663 14.6667V13.3333H20.5996V6.66667H19.2663V5.33333H17.9329V6.66667H19.2663V13.3333L17.9329 13.3333V14.6667L16.5996 14.66V10.6667H16.2568L16.1996 10L16.2441 10.6667H15.2663V9.33333H14.0425L13.9329 9.01395V8H9.93294ZM13.9329 9.10554L14.0292 9.33333H13.9329V9.10554ZM13.9329 9.33333H7.26628V10.6667H5.93294L5.93294 17.3333H7.26628V18.6667H13.9329L13.9329 17.3333H15.2663V10.6667H13.9329V9.33333Z"
				fill="currentColor"
			/>
		</svg>
	);
};
