import { IconComponent } from "@/ui/types";

export const SushiRoll: IconComponent = (props) => {
	return (
		<svg {...props} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_1938_2943)">
				<path
					d="M17.0002 8.86546C16.8595 9.11003 16.7194 9.35489 16.5784 9.59919C15.7898 10.9647 15.0017 12.3303 14.2122 13.6953C13.8339 14.3494 13.2409 14.7141 12.5388 14.9248C11.7953 15.1481 11.0322 15.1906 10.2683 15.0999C6.97804 14.7099 4.24451 13.2056 1.94952 10.8579C1.18425 10.0751 0.570388 9.17944 0.215644 8.12843C0.00680907 7.50927 -0.0923875 6.87139 0.1126 6.23792C0.224162 5.89391 0.40964 5.56974 0.590722 5.25328C1.41205 3.81667 2.24354 2.38584 3.07146 0.953091C3.09179 0.917837 3.1146 0.883684 3.13219 0.855591C3.08657 1.08171 3.0209 1.31059 2.99727 1.54387C2.92665 2.24483 3.11158 2.89923 3.40037 3.52775C3.873 4.55618 4.56793 5.42321 5.38431 6.1922C7.22535 7.92599 9.36673 9.12023 11.8236 9.72891C12.7031 9.94677 13.5976 10.0503 14.5046 9.96825C15.2869 9.89747 16.0275 9.7022 16.6496 9.18605C16.7095 9.13647 16.7653 9.08167 16.8199 9.02631C16.8746 8.97122 16.9257 8.91256 16.9785 8.85527C16.9856 8.85857 16.993 8.86215 17.0002 8.86546Z"
					fill="currentColor"
				/>
				<path
					d="M8.74851 2.36963C9.52065 2.38946 10.2318 2.62605 10.9056 2.98713C11.6414 3.38154 12.2885 3.88501 12.7661 4.58183C13.0024 4.92666 13.1816 5.29711 13.1662 5.7309C13.1503 6.1848 12.887 6.5153 12.4279 6.66734C11.989 6.81276 11.54 6.8045 11.0979 6.71086C9.7424 6.42386 8.61112 5.75899 7.75792 4.65206C7.51529 4.33698 7.33915 3.98499 7.29766 3.57846C7.2493 3.10281 7.46638 2.72465 7.9041 2.52772C8.17284 2.40681 8.45807 2.37624 8.74796 2.3699L8.74851 2.36963Z"
					fill="currentColor"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1938_2943">
					<rect width="17" height="14.2887" fill="white" transform="translate(0 0.855591)" />
				</clipPath>
			</defs>
		</svg>
	);
};
