import { IconComponent } from "@/ui/types";

export const Clipboard: IconComponent = (props) => {
	return (
		<svg {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M3.88892 15.278H4.72225V9.44466H3.88892V15.278Z" fill="currentColor" />
			<path
				d="M8.05558 4.44466H7.22225V8.61133H4.72225V9.44466H7.22225V11.9447H8.05558V4.44466Z"
				fill="currentColor"
			/>
			<path
				d="M10.5556 15.278H11.3889V12.778H15.5556V11.9447H8.05558V12.778H10.5556V15.278Z"
				fill="currentColor"
			/>
			<path d="M4.72225 15.278V16.1113H10.5556V15.278H4.72225Z" fill="currentColor" />
			<path d="M16.3889 4.44466H15.5556V11.9447H16.3889V4.44466Z" fill="currentColor" />
			<path d="M8.05558 3.61133V4.44466H15.5556V3.61133H8.05558Z" fill="currentColor" />
		</svg>
	);
};
