import { SaruIcon } from "./icons/saru-icon";

export const Splash = () => {
	return (
		<div className="fixed inset-0 bg-background z-50 flex items-center justify-center">
			<div className="absolute top-[calc(50%-20px)] left-[calc(50%-20px)]">
				<div className="w-[50px] h-[50px] animate-[bounce_.5s_linear_infinite_0.17s] absolute">
					<SaruIcon width={50} height={50} />
				</div>
				<div className="w-[50px] h-[5px] bg-white opacity-20 absolute top-[51px] left-0 rounded-[50%] animate-shadow" />
			</div>
		</div>
	);
};
