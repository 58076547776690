import { IconComponent } from "@/ui/types";

export const Exit: IconComponent = (props) => {
	return (
		<svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M18.3333 17.334L18.3333 18.334L17.3333 18.334L17.3333 17.334L18.3333 17.334Z"
				fill="currentColor"
			/>
			<path d="M18.3333 5.334L18.3333 6.334L17.3333 6.334L17.3333 5.334L18.3333 5.334Z" fill="currentColor" />
			<path
				d="M14.3333 11.334L14.3333 12.334L13.3333 12.334L13.3333 11.334L14.3333 11.334Z"
				fill="currentColor"
			/>
			<path
				d="M13.3333 12.334L13.3333 13.334L12.3333 13.334L12.3333 12.334L13.3333 12.334Z"
				fill="currentColor"
			/>
			<path
				d="M12.3333 13.334L12.3333 14.334L11.3333 14.334L11.3333 13.334L12.3333 13.334Z"
				fill="currentColor"
			/>
			<path
				d="M13.3333 10.334L13.3333 11.334L12.3333 11.334L12.3333 10.334L13.3333 10.334Z"
				fill="currentColor"
			/>
			<path
				d="M12.3333 9.334L12.3333 10.334L11.3333 10.334L11.3333 9.334L12.3333 9.334Z"
				fill="currentColor"
			/>
			<path
				d="M17.3333 19.334L17.3333 18.334L8.33325 18.334L8.33325 19.334L17.3333 19.334Z"
				fill="currentColor"
			/>
			<path
				d="M4.33325 11.334L4.33325 12.334L7.33325 12.334L7.33325 18.334L8.33325 18.334L8.33325 12.334L12.3333 12.334L12.3333 11.334L8.33325 11.334L8.33325 5.334L7.33325 5.334L7.33325 11.334L4.33325 11.334Z"
				fill="currentColor"
			/>
			<path
				d="M8.33325 4.33397L8.33325 5.334L17.3333 5.334L17.3333 4.334L8.33325 4.33397Z"
				fill="currentColor"
			/>
			<path
				d="M18.3333 17.334L19.3333 17.334L19.3333 6.33397L18.3333 6.334L18.3333 17.334Z"
				fill="currentColor"
			/>
		</svg>
	);
};
