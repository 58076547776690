import { Button } from "../components/button";
import { Coin } from "../components/icons/coin";
import { LinkExternal } from "../components/link";

export const BuyCrypto = () => {
	return (
		<LinkExternal href={""}>
			<Button
				variant="tertiary"
				className="uppercase"
				size={"sm"}
				icon={() => <Coin width={14} height={14} />}>
				Buy Crypto
			</Button>
		</LinkExternal>
	);
};
