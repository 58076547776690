"use client";
import { useAccount, useDisconnect, useSwitchChain } from "wagmi";
import { CHAIN_ID } from "@/constants/chain-id";
import { Dialog, DialogBody, DialogContent } from "./dialog";
import { Button } from "./button";
import { IS_TESTNET } from "@/constants/is-testnet";
import { ApeCoin } from "./icons/ape-coin";
import { toast } from "./toast";
import { Close } from "./icons/close";

export const WrongNetwork = () => {
	const { address, chainId } = useAccount();
	const { disconnectAsync } = useDisconnect();
	const { switchChainAsync } = useSwitchChain();

	const handleSwitchChain = async () => {
		try {
			await switchChainAsync({ chainId: CHAIN_ID });
		} catch (error) {
			toast({
				title: "Error",
				message: (error as Error)?.message ?? "An error occurred",
				type: "error",
			});
		}
	};

	const handleDisconnect = async () => {
		try {
			await disconnectAsync();
		} catch (error) {
			toast({
				title: "Error",
				message: (error as Error)?.message ?? "An error occurred",
				type: "error",
			});
		}
	};

	if (!address) {
		return null;
	}

	if (chainId === CHAIN_ID) {
		return null;
	}

	return (
		<Dialog open={true}>
			<DialogContent
				variant={"desktop"}
				overlayClassName="!bg-[#B9CCE6] dark:!bg-[#0A0A0B]"
				aria-describedby={undefined}
				className="double-rabbet-clip">
				<DialogBody className="double-rabbet-inner-clip flex flex-col px-10 pb-6 pt-10 gap-4 relative">
					<Button onClick={handleDisconnect} variant="ghost" size={"sm"} className="absolute top-3 right-3">
						<Close width={16} height={16} />
					</Button>
					<h1 className="text-2xl font-bold">Wrong Network</h1>
					<div className="flex flex-col items-center">
						<p>You are connected to the wrong network.</p>
						<div className="flex gap-2 items-center">
							<p>Please Switch to</p>
							<ApeCoin width={16} height={16} />
							<p>{IS_TESTNET ? "Curtis" : "ApeChain"} Network</p>
						</div>
					</div>
					<Button onClick={handleSwitchChain} className="uppercase w-full">
						Switch Network
					</Button>
				</DialogBody>
			</DialogContent>
		</Dialog>
	);
};
