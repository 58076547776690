import { Close } from "@/ui/components/icons/close";
import { useTheme } from "next-themes";
import { ReactNode } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const ToastProvider = ({ children }: { children: ReactNode }) => {
	const { theme } = useTheme();
	return (
		<>
			{children}
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				closeButton={(props) => (
					<Close {...props} width={14} height={14} className="mr-4 mt-4 min-h-[14px] min-w-[14px]" />
				)}
				draggable
				pauseOnHover
				theme={theme ? theme : "light"}
				toastClassName={`!font-carbon-plus toast-clip`}
				icon={false}
			/>
		</>
	);
};
