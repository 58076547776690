import { IconComponent } from "@/ui/types";

export const Moon: IconComponent = (props) => {
	return (
		<svg {...props} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M7.83325 0.333252H10.3333V1.16659H8.66658V1.99992H7.83325V0.333252Z" fill="currentColor" />
			<path d="M9.49992 4.49992H8.66658V1.99992H9.49992V4.49992Z" fill="currentColor" />
			<path d="M9.49992 6.99992V4.49992H10.3333V6.99992H9.49992Z" fill="currentColor" />
			<path d="M8.66658 8.66658V6.99992H9.49992V8.66658H8.66658Z" fill="currentColor" />
			<path d="M6.99992 9.49992V8.66658H8.66658V9.49992H6.99992Z" fill="currentColor" />
			<path d="M4.49992 9.49992H6.99992V10.3333H4.49992V9.49992Z" fill="currentColor" />
			<path d="M1.99992 8.66658H4.49992V9.49992H1.99992V8.66658Z" fill="currentColor" />
			<path d="M1.16659 10.3333H0.333252V7.83325H1.99992V8.66658H1.16659V10.3333Z" fill="currentColor" />
			<path d="M1.99992 11.1666H1.16659V10.3333H1.99992V11.1666Z" fill="currentColor" />
			<path d="M2.83325 11.9999H1.99992V11.1666H2.83325V11.9999Z" fill="currentColor" />
			<path d="M4.49992 12.8333H2.83325V11.9999H4.49992V12.8333Z" fill="currentColor" />
			<path d="M9.49992 12.8333V13.6666H4.49992V12.8333H9.49992Z" fill="currentColor" />
			<path d="M10.3333 11.9999V12.8333H9.49992V11.9999H10.3333Z" fill="currentColor" />
			<path d="M11.1666 11.1666V11.9999H10.3333V11.1666H11.1666Z" fill="currentColor" />
			<path d="M11.9999 10.3333V11.1666H11.1666V10.3333H11.9999Z" fill="currentColor" />
			<path d="M12.8333 8.66658V10.3333H11.9999V8.66658H12.8333Z" fill="currentColor" />
			<path d="M12.8333 4.49992H13.6666V8.66658H12.8333V4.49992Z" fill="currentColor" />
			<path d="M11.9999 2.83325H12.8333V4.49992H11.9999V2.83325Z" fill="currentColor" />
			<path d="M11.1666 1.99992H11.9999V2.83325H11.1666V1.99992Z" fill="currentColor" />
			<path d="M11.1666 1.99992V1.16659H10.3333V1.99992H11.1666Z" fill="currentColor" />
		</svg>
	);
};
