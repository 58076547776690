import { cn } from "@/lib/utils";
import { SushiRoll } from "./icons/sushi-roll";
import { LinkExternal } from "./link";

export const PoweredBy = ({ className }: { className?: string }) => {
	return (
		<LinkExternal
			href="https://sushilabs.com"
			className={cn("flex items-center gap-1 hover:!no-underline", className ?? "")}>
			Powered By <SushiRoll width={17} height={15} /> Sushi Labs
		</LinkExternal>
	);
};
