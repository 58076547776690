import { IconComponent } from "@/ui/types";

export const MobileSettingsIcon: IconComponent = (props) => {
	return (
		<svg {...props} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M8.46675 3V5.5715H16.1807V3H8.46675Z" fill="currentColor" />
			<path d="M5.89524 5.57151L8.46675 5.5715L8.46674 8.14301H5.89524V5.57151Z" fill="currentColor" />
			<path d="M8.46675 18.4285V21H16.1807V18.4285H8.46675Z" fill="currentColor" />
			<path d="M3.32373 10.7145H5.89523V15.857H3.32373V10.7145Z" fill="currentColor" />
			<path d="M5.89523 15.857H8.46674L8.46675 18.4285L5.89524 18.4285L5.89523 15.857Z" fill="currentColor" />
			<path d="M18.7522 5.57151L16.1807 5.5715L16.1807 8.14301H18.7522V5.57151Z" fill="currentColor" />
			<path d="M18.7522 15.857H21.3237V10.7145H18.7522V15.857Z" fill="currentColor" />
			<path d="M5.89524 8.14301L3.32373 8.14302V10.7145H5.89523L5.89524 8.14301Z" fill="currentColor" />
			<path d="M11.0383 10.7145H13.6098V13.286H11.0383V10.7145Z" fill="currentColor" />
			<path d="M21.3237 8.14302L18.7522 8.14301L18.7522 10.7145H21.3237V8.14302Z" fill="currentColor" />
			<path d="M18.7522 15.857H16.1807L16.1807 18.4285L18.7522 18.4285L18.7522 15.857Z" fill="currentColor" />
		</svg>
	);
};
