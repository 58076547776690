import { IconComponent } from "@/ui/types";

export const X: IconComponent = (props) => {
	return (
		<svg {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M10.9089 0.727295V1.45457H12.3634V2.18184H13.0907V2.90911H13.818V3.63639H14.5452V5.09093H15.2725V10.9091H14.5452V12.3637H13.818V13.0909H13.0907V13.8182H12.3634V14.5455H10.9089V15.2727H5.09069V14.5455H3.63614V13.8182H2.90887V13.0909H2.1816V12.3637H1.45432V10.9091H0.727051V5.09093H1.45432V3.63639H2.1816V2.90911H2.90887V2.18184H3.63614V1.45457H5.09069V0.727295H10.9089ZM9.45432 11.6364H10.9089V9.45457H10.1816V8.72729H9.45432V7.27275H10.1816V6.54548H10.9089V4.36366H9.45432V5.8182H8.72705V6.54548H7.2725V5.8182H6.54523V4.36366H5.09069V6.54548H5.81796V7.27275H6.54523V8.72729H5.81796V9.45457H5.09069V11.6364H6.54523V10.1818H7.2725V9.45457H8.72705V10.1818H9.45432V11.6364Z"
				fill="currentColor"
			/>
		</svg>
	);
};
