import { IconComponent } from "@/ui/types";

export const InfoHover: IconComponent = (props) => {
	return (
		<svg {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M6.00016 11.9997V12.6663H10.0002V11.9997H6.00016Z" fill="currentColor" />
			<path d="M4.66683 11.333V11.9997H6.00016V11.333H4.66683Z" fill="currentColor" />
			<path d="M4.66683 9.99967H4.00016V11.333H4.66683V9.99967Z" fill="currentColor" />
			<path d="M3.3335 9.99967H4.00016V5.99967H3.3335V9.99967Z" fill="currentColor" />
			<path d="M4.00016 5.99967H4.66683V4.66634H4.00016L4.00016 5.99967Z" fill="currentColor" />
			<path d="M4.66683 3.99967V4.66634H6.00016V3.99967H4.66683Z" fill="currentColor" />
			<path d="M6.00016 3.33301V3.99967H10.0002V3.33301H6.00016Z" fill="currentColor" />
			<path d="M11.3335 4.66634V3.99967H10.0002V4.66634H11.3335Z" fill="currentColor" />
			<path d="M11.3335 5.99967H12.0002V4.66634H11.3335V5.99967Z" fill="currentColor" />
			<path d="M12.0002 9.99967H12.6668V5.99967H12.0002V9.99967Z" fill="currentColor" />
			<path d="M12.0002 9.99967H11.3335V11.333H12.0002V9.99967Z" fill="currentColor" />
			<path d="M11.3335 11.9997V11.333H10.0002V11.9997H11.3335Z" fill="currentColor" />
			<path d="M7.66675 6H8.33342V8.66667H7.66675V6Z" fill="currentColor" />
			<path d="M7.66675 9.33333H8.33342V10H7.66675V9.33333Z" fill="currentColor" />
		</svg>
	);
};
