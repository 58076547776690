import { TokenIcon } from "../shared/token-icon";

export const WalletSection = () => {
	//@DEV @TODO hook to get data for tokens

	return (
		<div className="flex flex-col pr-2">
			{new Array(4).fill(null).map((_, i) => (
				<WalletItem key={i} />
			))}
		</div>
	);
};

const WalletItem = () => {
	return (
		<div className="flex justify-between w-full py-2 items-center">
			<div className="flex gap-2 items-center">
				<TokenIcon width={42} height={42} currency={undefined} />
				<div className="flex flex-col">
					<div className="font-bold">{"SYM"}</div>
					<div className="text-sm">{"Chain?"}</div>
				</div>
			</div>
			<div className="flex flex-col items-end">
				<div>{"$12,1212.01"}</div>
				<div className="text-sm">{"31,233 SYM"}</div>
			</div>
		</div>
	);
};

// @DEV @TODO -> skeleton base:
{
	/* <div className="flex justify-between w-full">
<div className="flex gap-2">
  <div className="w-8 h-8 bg-gray-200 rounded-full"></div>
  <div className="flex flex-col">
    <div className="w-24 h-4 bg-gray-200 rounded"></div>
    <div className="w-16 h-4 bg-gray-200 rounded"></div>
  </div>
</div>
<div className="flex flex-col items-end">
  <div className="w-24 h-4 bg-gray-200 rounded"></div>
  <div className="w-16 h-4 bg-gray-200 rounded"></div>
</div>
</div> */
}
