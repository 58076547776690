import { IconComponent } from "@/ui/types";

export const Stake: IconComponent = (props) => {
	return (
		<svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M12.5294 19.9412H9.35294V18.8824H8.29412V19.9412V21H12.5294V19.9412ZM16.7647 21V19.9412H15.7059V21H16.7647ZM15.7059 19.9412V18.8824H14.6471V19.9412H15.7059ZM14.6471 18.8824V17.8235H13.5882V18.8824H14.6471ZM13.5882 16.7647H12.5294V13.5882L17.8235 13.5882V12.5294L12.5294 12.5294V9.35294H13.5882V8.29412H12.5294V6.17647H11.4706V9.35294H6.17647V10.4118H11.4706V16.7647H10.4118V17.8235H13.5882L13.5882 16.7647ZM10.4118 17.8235H9.35294V18.8824H10.4118V17.8235ZM11.4706 6.17647V5.11765H10.4118V6.17647H11.4706ZM5.11765 8.29412V9.35294H6.17647V8.29412H5.11765ZM10.4118 5.11765V4.05882H9.35294V5.11765H10.4118ZM4.05882 7.23529V8.29412H5.11765V7.23529H4.05882ZM9.35294 4.05882V3L3 3L3 7.23529H4.05882V4.05882H9.35294ZM17.8235 12.5294H18.8824V11.4706H17.8235V12.5294ZM14.6471 7.23529H13.5882V8.29412H14.6471V7.23529ZM18.8824 11.4706H19.9412V10.4118H18.8824V11.4706ZM19.9412 10.4118H21V6.17647H14.6471V7.23529H19.9412V10.4118Z"
				fill="currentColor"
			/>
		</svg>
	);
};
