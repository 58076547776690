import {
	QueryClientProvider as _QueryClientProvider,
	QueryCache,
	QueryClient,
	type QueryClientConfig,
} from "@tanstack/react-query";
import { FC, ReactNode } from "react";

const queryClientConfig = {
	defaultOptions: {
		queries: {
			staleTime: 1000 * 60 * 60 * 24, // 24 hours
		},
	},
	queryCache: new QueryCache({
		onError: (error) => {
			console.error("react query global onError", error);
			// Sentry.captureException(error);
		},
	}),
	// deprecated in next version so there's no point using
	// logger: {
	//   log: (message) => {
	//     Sentry.captureMessage(message)
	//   },
	//   warn: (message) => {
	//     Sentry.captureMessage(message)
	//   },
	//   error: (error) => {
	//     Sentry.captureException(error)
	//   },
	// },
};

const createQueryClient = (config: QueryClientConfig | undefined = queryClientConfig) => {
	return new QueryClient(config);
};

const client = createQueryClient();

export const QueryClientProvider: FC<{ children: ReactNode }> = ({ children }) => {
	return (
		<_QueryClientProvider client={client}>
			{children}
			{/* <ReactQueryDevtools initialonlyOpen={false} /> */}
		</_QueryClientProvider>
	);
};
