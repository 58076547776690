import { IconComponent } from "@/ui/types";

export const Sort: IconComponent = (props) => {
	return (
		<svg {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M13 4.16671H14.5L14.5 12.9167H16V14.375H14.5V15.8334H13V14.375H11.5V12.9167H13L13 4.16671ZM11.5 12.9167V11.4584H10V12.9167L11.5 12.9167ZM16 12.9167V11.4584H17.5V12.9167H16ZM5.5 15.8334H7L7 7.08337H8.5V8.54171L10 8.54171L10 7.08337H8.5V5.62504H7V4.16671H5.5V5.62504H4V7.08337H2.5V8.54171L4 8.54171V7.08337H5.5L5.5 15.8334Z"
				fill="currentColor"
			/>
		</svg>
	);
};
