"use client";

import { ThemeProvider as NextThemeProvider } from "next-themes";
import { FC, ReactNode } from "react";

interface ThemeProviderProps {
	children: ReactNode | ReactNode[];
	forcedTheme?: string;
}

export const ThemeProvider: FC<ThemeProviderProps> = ({ children, forcedTheme }) => {
	return (
		<NextThemeProvider
			attribute="class"
			enableSystem={false}
			enableColorScheme={true}
			disableTransitionOnChange
			forcedTheme={forcedTheme}>
			{children}
		</NextThemeProvider>
	);
};
