import { cn } from "@/lib/utils";
import { FC, SVGProps } from "react";

interface ChevronProps extends SVGProps<SVGSVGElement> {
	direction?: "up" | "down" | "left" | "right";
}

export const Chevron: FC<ChevronProps> = ({ direction = "down", ...props }) => {
	return (
		<svg
			{...props}
			className={cn(
				props.className,
				direction === "up" && "transform rotate-180",
				direction === "left" && "transform rotate-90",
				direction === "right" && "transform rotate-[270deg]"
			)}
			viewBox="0 0 14 8"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path d="M2 0H0V2H2V4H4V6H6V8H8V6H10V4H12V2H14V0H12V2H10V4H8V6H6V4H4V2H2V0Z" fill="currentColor" />
		</svg>
	);
};
