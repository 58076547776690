import { useCallback, useEffect, useState } from "react";

type CopiedValue = string | null;

export type CopyFn = (text: string) => Promise<boolean>;

type IsCopied = boolean;

export function useCopyToClipboard(): [CopiedValue, CopyFn, IsCopied] {
	const [copiedText, setCopiedText] = useState<CopiedValue>(null);
	const [isCopied, setIsCopied] = useState(false);

	const copy: CopyFn = useCallback(async (text) => {
		if (!navigator?.clipboard) {
			console.warn("Clipboard not supported");
			return false;
		}

		// Try to save to clipboard then save it in the state if worked
		try {
			await navigator.clipboard.writeText(text);
			setCopiedText(text);
			setIsCopied(true);
			return true;
		} catch (error) {
			console.warn("Copy failed", error);
			setCopiedText(null);
			return false;
		}
	}, []);

	useEffect(() => {
		if (isCopied) {
			const timer = setTimeout(() => {
				setIsCopied(false);
			}, 2000);
			return () => clearTimeout(timer);
		}
	}, [isCopied]);

	return [copiedText, copy, isCopied];
}
