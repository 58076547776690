import { useAccount, useEnsAvatar, useEnsName } from "wagmi";
import { cn } from "@/lib/utils";
import Image from "next/image";

const addressToNumber = (address: string) => {
	return (
		(address
			.split("")
			.map((l) => l.charCodeAt(0))
			.reduce((a, b) => a + b) %
			100) /
		100
	);
};

const COLORS: Record<string, string> = {
	"ens-01-start": "#FF453A",
	"ens-01-stop": "#FF9F0A",
	"ens-02-start": "#FF9F0A",
	"ens-02-stop": "#FFD60A",
	"ens-03-start": "#FFD60A",
	"ens-03-stop": "#32D74B",
	"ens-04-start": "#32D74B",
	"ens-04-stop": "#64D2FF",
	"ens-05-start": "#64D2FF",
	"ens-05-stop": "#0A84FF",
	"ens-06-start": "#0A84FF",
	"ens-06-stop": "#5E5CE6",
	"ens-07-start": "#5E5CE6",
	"ens-07-stop": "#BF5AF2",
	"ens-08-start": "#BF5AF2",
	"ens-08-stop": "#FF2D55",
};

export const Avatar = ({ size }: { size: "sm" | "lg" }) => {
	const { address } = useAccount();
	const { data: name } = useEnsName({
		chainId: 1,
		address,
	});

	const { data: avatar } = useEnsAvatar({
		name: name || undefined,
		chainId: 1,
	});

	const id = Math.ceil(addressToNumber(address ?? "") * 8);
	const ensColor = `0${id === 0 ? 1 : id}`;
	return (
		<>
			{avatar ? (
				<Image
					alt="ens-avatar"
					src={avatar}
					width={size === "sm" ? 30 : 80}
					height={size === "sm" ? 30 : 80}
					className={cn(
						`pfp-clip`,
						size === "sm" ? "w-[16px] h-[16px] md:w-[30px] md:h-[30px]" : "w-[80px] h-[80px]"
					)}
				/>
			) : (
				<div
					className={cn(
						`pfp-clip`,
						size === "sm"
							? "w-[16px] h-[16px] md:w-[30px] md:h-[30px]"
							: "w-[80px] h-[80px] min-w-[80px] min-h-[80px]"
					)}
					style={{
						background: `linear-gradient(180deg, ${COLORS[`ens-${ensColor}-start`]} 0%, ${
							COLORS[`ens-${ensColor}-stop`]
						} 100%)`,
					}}
				/>
			)}
		</>
	);
};
