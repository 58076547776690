import { useAccount } from "wagmi";
import { Button } from "../components/button";
import { External } from "../components/icons/external";
import { LinkExternal } from "../components/link";
import { getBlockExplorerForAddress } from "@/utils/block-explorer";
import { useMemo } from "react";

export const Explorer = () => {
	const { address, chainId } = useAccount();

	const url = useMemo(() => {
		if (chainId === undefined || !address) return "";
		return getBlockExplorerForAddress(chainId, address);
	}, [address, chainId]);

	return (
		<LinkExternal href={url}>
			<Button
				variant="tertiary"
				className="uppercase"
				size={"sm"}
				icon={() => <External width={14} height={14} />}>
				Explorer
			</Button>
		</LinkExternal>
	);
};
