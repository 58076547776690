import { ScrollArea } from "../components/scroll-area";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../components/tabs";
import { ActivitySection } from "./activity-section";
import { WalletSection } from "./wallet-section";

export const WalletActivityTabs = () => {
	return (
		<Tabs defaultValue="wallet" className="w-full">
			<TabsList className="w-full">
				<TabsTrigger className="w-full" value="wallet">
					Wallet
				</TabsTrigger>
				<TabsTrigger className="w-full" value="activity">
					Activity
				</TabsTrigger>
			</TabsList>
			<TabsContent value="wallet" className="h-[320px] md:h-[260px]">
				<ScrollArea>
					<WalletSection />
				</ScrollArea>
			</TabsContent>
			<TabsContent value="activity" className="h-[320px] md:h-[260px]">
				<ScrollArea>
					<ActivitySection />
				</ScrollArea>
			</TabsContent>
		</Tabs>
	);
};
