import { Button } from "../components/button";
import { External } from "../components/icons/external";
import { LinkExternal } from "../components/link";
import { TokenIcon } from "../shared/token-icon";

export const ActivitySection = () => {
	//@DEV @TODO hook to get data for activity
	const data = true;
	if (!data) {
		return (
			<p className="text-sm text-center text-muted-foreground mt-4">
				Swap or add liquidity to see a <br /> transaction record here.
			</p>
		);
	}

	return (
		<div className="flex flex-col pr-2">
			{new Array(2).fill(null).map((_, i) => (
				<ActivityItem key={i} />
			))}
		</div>
	);
};

const ActivityItem = () => {
	return (
		<div className="flex justify-between w-full py-2 items-center">
			<div className="flex gap-2 items-center">
				<TokenIcon width={42} height={42} currency={undefined} />
				<div className="flex flex-col">
					<div className="font-bold">{"Swapped"}</div>
					<div className="text-sm">{"0.00056 ETH for 1 USDC"}</div>
				</div>
			</div>
			<div className="flex items-center text-sm">
				<div>{"1s"}</div>
				<LinkExternal>
					<Button variant="ghost">
						<External width={12} height={12} />
					</Button>
				</LinkExternal>
			</div>
		</div>
	);
};

// @DEV @TODO -> skeleton base:
{
	/* <div className="flex justify-between w-full">
<div className="flex gap-2">
  <div className="w-8 h-8 bg-gray-200 rounded-full"></div>
  <div className="flex flex-col">
    <div className="w-24 h-4 bg-gray-200 rounded"></div>
    <div className="w-16 h-4 bg-gray-200 rounded"></div>
  </div>
</div>
			<div className="flex items-center text-sm gap-1">
  <div className="w-24 h-4 bg-gray-200 rounded"></div>
  <div className="w-16 h-4 bg-gray-200 rounded"></div>
</div>
</div> */
}
