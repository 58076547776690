"use client";

import { Dialog, DialogBody, DialogContent } from "./dialog";
import { useLocalStorage } from "@/hooks/useLocalStorage";
import { useState } from "react";
import { LinkInternal } from "./link";
import { Button } from "./button";
import { Divider } from "./divider";
import { Switch } from "./switch";

export const CookieConsent = () => {
	const [hasSeenCookieConsent, setHasSeenCookieConsent] = useLocalStorage("hasSeenCookieConsent", false);
	const [view, setView] = useState<"index" | "manage">("index");

	const handleAcceptAll = () => {
		// Accept all cookies
		setHasSeenCookieConsent(true);
	};
	const handleManageCookies = () => {
		// Manage cookies
		setView("manage");
	};

	const handleRejectAllNonEssential = () => {
		// Reject all non-essential cookies
		//TODO get reject logic
		setHasSeenCookieConsent(true);
	};

	const confirmChoices = () => {
		// Confirm choices
		//TODO get confirm choices logic
		setHasSeenCookieConsent(true);
	};

	if (hasSeenCookieConsent) {
		return null;
	}

	return (
		<Dialog open={true}>
			<DialogContent
				variant={"desktop"}
				aria-describedby={undefined}
				className="double-rabbet-inner-clip dark:bg-popover bg-[#9DB7E7] !max-w-2xl md:!top-[90%] md:!translate-y-[-90%] !top-[80%] !translate-y-[-80%]">
				<DialogBody className="p-0">
					{view === "index" ? (
						<IndexView
							handleAcceptAll={handleAcceptAll}
							handleManageCookies={handleManageCookies}
							handleRejectAllNonEssential={handleRejectAllNonEssential}
						/>
					) : null}
					{view === "manage" ? (
						<ManageView
							confirmChoices={confirmChoices}
							handleRejectAllNonEssential={handleRejectAllNonEssential}
						/>
					) : null}
				</DialogBody>
			</DialogContent>
		</Dialog>
	);
};

const ManageView = ({
	handleRejectAllNonEssential,
	confirmChoices,
}: {
	handleRejectAllNonEssential: () => void;
	confirmChoices: () => void;
}) => {
	const [strictlyNecessary, setStrictlyNecessary] = useLocalStorage("strictly-necessary-cookies", true);
	const [performance, setPerformance] = useLocalStorage("performance-cookies", true);
	const [functional, setFunctional] = useLocalStorage("functional-cookies", true);
	return (
		<div className="flex flex-col w-full">
			<div className="bg-white dark:bg-dialog-content py-3 px-6 w-full">
				<h1 className="text-lg font-bold">MANAGE COOKIE PREFERENCES</h1>
			</div>
			<div className="flex flex-col gap-4 px-6 py-3">
				<div className="flex justify-between items-center gap-2">
					<p>Strictly Necessary Cookies</p>
					<Switch
						checked={strictlyNecessary}
						onCheckedChange={() => setStrictlyNecessary(!strictlyNecessary)}
					/>
				</div>
				<Divider className="bg-[#2E323866]" />
				<div className="flex justify-between items-center gap-2">
					<p>Performance Cookies</p>
					<Switch checked={performance} onCheckedChange={() => setPerformance(!performance)} />
				</div>
				<Divider className="bg-[#2E323866]" />
				<div className="flex justify-between items-center gap-2">
					<p>Functional Cookies</p>
					<Switch checked={functional} onCheckedChange={() => setFunctional(!functional)} />
				</div>
				<div className="flex flex-col gap-3 md:gap-8 md:flex-row items-center w-full justify-center mt-4">
					<Button onClick={confirmChoices} className="uppercase w-full md:w-fit">
						Confirm My Choices
					</Button>
					<Button
						variant={"regular"}
						onClick={handleRejectAllNonEssential}
						className="uppercase w-full md:w-fit">
						Reject all non-essential
					</Button>
				</div>
			</div>
		</div>
	);
};

const IndexView = ({
	handleAcceptAll,
	handleManageCookies,
	handleRejectAllNonEssential,
}: {
	handleAcceptAll: () => void;
	handleManageCookies: () => void;
	handleRejectAllNonEssential: () => void;
}) => {
	return (
		<div className="flex flex-col p-6 gap-2 relative">
			<h1 className="text-lg font-bold">
				Sushi Labs and our third-party service providers may use cookies as set forth in our Cookie Policy
			</h1>
			<p className="text-sm">
				Which process your personal data. You may manage your cookie preferences below. Even if you reject all
				cookies, you hereby consent to the collection of your personal data by us and our service providers as
				you use our services, through technologies other than cookies, as described in our{" "}
				<LinkInternal href="/privacy-policy" className="text-accent underline">
					Privacy Policy
				</LinkInternal>
				, including when such collection may be considered an interception of communications by third parties.
			</p>
			<Divider className="my-2" />
			<div className="flex flex-col gap-3 md:gap-8 md:flex-row items-center w-full justify-center">
				<Button onClick={handleAcceptAll} className="uppercase w-full md:w-fit">
					Accept All
				</Button>
				<Button variant={"regular"} onClick={handleManageCookies} className="uppercase w-full md:w-fit">
					Manage cookies
				</Button>
				<Button
					variant={"regular"}
					onClick={handleRejectAllNonEssential}
					className="uppercase w-full md:w-fit">
					Reject all non-essential
				</Button>
			</div>
		</div>
	);
};
