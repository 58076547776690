import { IconComponent } from "@/ui/types";

export const Settings: IconComponent = (props) => {
	return (
		<svg {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M9 2.00001L10 2.00003L10 1.00001H9V2.00001Z" fill="currentColor" />
			<path d="M7 2.00001L6 2.00003L6 1.00001L7 1.00001V2.00001Z" fill="currentColor" />
			<path d="M14 7H15V6L14 6.00003L14 7Z" fill="currentColor" />
			<path d="M2 7H0.999998L0.999998 6H2L2 7Z" fill="currentColor" />
			<path d="M14 10H15L15 9.00003L14 9V10Z" fill="currentColor" />
			<path d="M2 10H0.999998L1 9.00003L2 9V10Z" fill="currentColor" />
			<path d="M9 15L10 15L10 14L9 14L9 15Z" fill="currentColor" />
			<path d="M7 15L6 15L6 14L7 14L7 15Z" fill="currentColor" />
			<path d="M11 6H10V10H11V6Z" fill="currentColor" />
			<path d="M10 6L10 5.00003L6 5.00003L6 6L10 6Z" fill="currentColor" />
			<path d="M6 6H5L5 10L6 10L6 6Z" fill="currentColor" />
			<path d="M10 11L10 10H6L6 11H10Z" fill="currentColor" />
			<path d="M3 13L3 14L6 14L6 13L3 13Z" fill="currentColor" />
			<path d="M2 13H3L3 10L2 10L2 13Z" fill="currentColor" />
			<path d="M0.999998 7L0 7.00001L0 9.00003H1L0.999998 7Z" fill="currentColor" />
			<path d="M2 6L3 6.00003L3 3.00003L2 3L2 6Z" fill="currentColor" />
			<path d="M6 3.00003L6 2.00003L3 2.00003V3.00003L6 3.00003Z" fill="currentColor" />
			<path d="M9 1.00001L9 0L7 0L7 1.00001L9 1.00001Z" fill="currentColor" />
			<path d="M10 2.00003V3.00003L13 3.00003V2.00003L10 2.00003Z" fill="currentColor" />
			<path d="M13 6.00003L14 6.00003L14 3L13 3.00003L13 6.00003Z" fill="currentColor" />
			<path d="M16 7.00001L15 7L15 9.00003H16V7.00001Z" fill="currentColor" />
			<path d="M13 13H14L14 10L13 10V13Z" fill="currentColor" />
			<path d="M13 14L13 13L10 13V14H13Z" fill="currentColor" />
			<path d="M9 16V15H7V16H9Z" fill="currentColor" />
		</svg>
	);
};
