import { IconComponent } from "@/ui/types";

export const Bridge: IconComponent = (props) => {
	return (
		<svg {...props} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M3.39844 5V3H5.39844V5H3.39844ZM7.39844 7H5.39844V5H7.39844V7ZM9.39844 9H7.39844V7H9.39844V9ZM11.3984 11H9.39844V9H11.3984V11ZM13.3984 11H11.3984V13H13.3984V15H15.3984V17H17.3984V19H15.3984V21H21.3984V15H19.3984V17H17.3984V15H15.3984V13H13.3984V11ZM15.3984 9V11H13.3984V9H15.3984ZM17.3984 7V9H15.3984V7H17.3984ZM17.3984 5V7H19.3984V9H21.3984V3H15.3984V5H17.3984ZM5.39844 19V17H7.39844V19H5.39844ZM5.39844 19V21H3.39844V19H5.39844ZM7.39844 17V15H9.39844V17H7.39844Z"
				fill="currentColor"
			/>
		</svg>
	);
};
