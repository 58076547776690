"use client";

import { WagmiStoreVersionCheck } from "@/lib/wagmi/wagmi-store-version-check";
import { QueryClientProvider } from "@/providers/query-client-provider";
import { ThemeProvider } from "@/providers/theme-provider";
import { WagmiConfig } from "@/providers/wagmi-provider";
import { State } from "wagmi";
import { DeferUntilWalletReady } from "@/ui/components/defer-until-wallet-ready";
import { ToastProvider } from "@/providers/toast-provider";

export function Providers({
	children,
	initialWagmiState,
}: {
	children: React.ReactNode;
	initialWagmiState?: State | undefined;
}) {
	return (
		<WagmiConfig initialState={initialWagmiState}>
			<QueryClientProvider>
				<WagmiStoreVersionCheck>
					<ThemeProvider>
						<DeferUntilWalletReady>
							<ToastProvider>{children}</ToastProvider>
						</DeferUntilWalletReady>
					</ThemeProvider>
				</WagmiStoreVersionCheck>
			</QueryClientProvider>
		</WagmiConfig>
	);
}
