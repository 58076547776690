import { cookieToInitialState } from "@wagmi/core";
import { createProductionConfig } from "./production";

export { createProductionConfig };

export const wagmiConfig = (() => {
	const config = (() => {
		return createProductionConfig();
	})();

	return config;
})();

export const getWagmiInitialState = (cookieHeaders: string | null | undefined) => {
	const initialState = cookieToInitialState(wagmiConfig, cookieHeaders);
	return initialState;
};
