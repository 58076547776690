import { shortenAddress } from "@lufycz/sushi/format";
import { useAccount } from "wagmi";
import { Clipboard } from "../components/icons/clipboard";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard";
import { Check } from "../components/icons/check";

export const UserAddress = () => {
	const { address } = useAccount();
	const [, copy, isCopied] = useCopyToClipboard();

	return (
		<button
			onClick={() => {
				copy(address as string);
			}}
			className="flex items-center font-bold gap-2">
			<div>{shortenAddress(address as string)}</div>
			<div>{isCopied ? <Check width={20} height={20} /> : <Clipboard width={20} height={20} />}</div>
		</button>
	);
};
