import { usePathname } from "next/navigation";
import { LinkInternal } from "./link";
import { cn } from "@/lib/utils";
import { ReactNode } from "react";
import { Sort } from "./icons/sort";
import { Coins } from "./icons/coins";
import { Stake } from "./icons/stake";
import { Bridge } from "./icons/bridge";
import { Portfolo } from "./icons/portfolio";

export const MobileNavigation = () => {
	const pathname = usePathname();

	return (
		<aside className="fixed md:hidden border-t md:invisible text-primary bg-dialog-content w-full bottom-0 left-0 flex justify-between items-center gap-2 py-1 px-3 z-50">
			<NavItem href="/swap" isActive={pathname.includes("swap")} label="Swap">
				<Sort width={24} height={24} className="rotate-90" />
			</NavItem>
			<NavItem href="/pools" isActive={pathname.includes("pool")} label="Pools">
				<Coins width={24} height={24} />
			</NavItem>
			<NavItem href="/stake" isActive={pathname.includes("stake")} label="Stake">
				<Stake width={24} height={24} />
			</NavItem>
			<NavItem href="/bridge" isActive={pathname.includes("bridge")} label="Bridge">
				<Bridge width={24} height={24} />
			</NavItem>
			<NavItem href="/portfolio" isActive={pathname.includes("portfolio")} label="Portfolio">
				<Portfolo width={24} height={24} />
			</NavItem>
		</aside>
	);
};

const NavItem = ({
	children,
	href,
	isActive,
	label,
}: {
	children: ReactNode;
	href: string;
	isActive: boolean;
	label: string;
}) => {
	return (
		<LinkInternal
			href={href}
			className={cn(
				"px-2 py-3 bg-transparent w-full flex flex-col gap-1 items-center justify-center",
				isActive ? "text-accent" : ""
			)}>
			{children}
			<span className="text-[10px]">{label}</span>
		</LinkInternal>
	);
};
