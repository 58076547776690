import { IconComponent } from "@/ui/types";

export const Portfolo: IconComponent = (props) => {
	return (
		<svg {...props} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M11.3373 10.8462V9.53846H6.10652L6.10652 10.8462H11.3373Z" fill="currentColor" />
			<path d="M6.10652 4.30769L4.79883 4.30769L4.79883 9.53846H6.10652V4.30769Z" fill="currentColor" />
			<path d="M11.3373 4.30769V3L6.10652 3V4.30769L11.3373 4.30769Z" fill="currentColor" />
			<path d="M12.645 4.30769L11.3373 4.30769V9.53846H12.645V4.30769Z" fill="currentColor" />
			<path d="M15.2604 4.30769L13.9527 4.30769V9.53846H15.2604V4.30769Z" fill="currentColor" />
			<path d="M20.4911 4.30769V3L15.2604 3V4.30769L20.4911 4.30769Z" fill="currentColor" />
			<path d="M20.4911 10.8462V9.53846H15.2604V10.8462H20.4911Z" fill="currentColor" />
			<path d="M21.7988 4.30769L20.4911 4.30769V9.53846H21.7988V4.30769Z" fill="currentColor" />
			<path d="M6.10652 13.4615H4.79883L4.79883 18.6923H6.10652V13.4615Z" fill="currentColor" />
			<path d="M11.3373 13.4615V12.1538H6.10652V13.4615H11.3373Z" fill="currentColor" />
			<path d="M11.3373 20V18.6923H6.10652L6.10652 20H11.3373Z" fill="currentColor" />
			<path d="M12.645 13.4615H11.3373V18.6923H12.645V13.4615Z" fill="currentColor" />
			<path d="M15.2604 13.4615H13.9527L13.9527 18.6923H15.2604L15.2604 13.4615Z" fill="currentColor" />
			<path d="M20.4911 20V18.6923H15.2604V20H20.4911Z" fill="currentColor" />
			<path d="M21.7988 13.4615H20.4911V18.6923H21.7988V13.4615Z" fill="currentColor" />
			<path d="M20.4911 13.4615V12.1538L15.2604 12.1538V13.4615L20.4911 13.4615Z" fill="currentColor" />
		</svg>
	);
};
