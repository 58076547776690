import { useDisconnect } from "wagmi";
import { Button } from "../components/button";
import { Exit } from "../components/icons/exit";
import { toast } from "../components/toast";
import { cn } from "@/lib/utils";

export const Disconnect = ({ className }: { className?: string }) => {
	const { disconnectAsync } = useDisconnect();
	const handleDisconnect = async () => {
		try {
			await disconnectAsync();
		} catch (error) {
			toast({
				title: "Error",
				message: (error as Error)?.message ?? "An error occurred",
				type: "error",
			});
		}
	};
	return (
		<Button variant={"ghost"} onClick={handleDisconnect} className={cn("p-0", className ?? "")}>
			<Exit width={28} height={28} />
		</Button>
	);
};
