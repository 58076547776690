import { useSlippageTolerance } from "@/hooks/useSlippageTolerance";
import { Divider } from "../components/divider";
import { Switch } from "../components/switch";
import { FC } from "react";
import { ToggleItem, ToggleRoot } from "../components/toggle-group";
import { TextField } from "../components/text-field";
import { FullHoverCard } from "../components/hover-card";
import { motion, AnimatePresence } from "framer-motion";
import { cn } from "@/lib/utils";

const OPTIONS = [
	{
		value: "0.1",
		label: "0.1 %",
	},
	{
		value: "0.5",
		label: "0.5 %",
	},
	{
		value: "1.0",
		label: "1.0 %",
	},
];

export const SlippageTolerance: FC<{
	options?: {
		storageKey?: string;
		defaultValue?: string;
		title?: string;
	};
}> = ({ options }) => {
	const [slippageTolerance, setSlippageTolerance] = useSlippageTolerance(
		options?.storageKey,
		options?.defaultValue
	);
	const isDangerous =
		(!Number.isNaN(+slippageTolerance) && +slippageTolerance >= 1.3) ||
		(!Number.isNaN(+slippageTolerance) && +slippageTolerance < 0.1 && +slippageTolerance > 0);
	return (
		<div className="flex-col flex gap-2 w-full">
			<div className="flex items-center justify-between w-full">
				<h6 className="text-sm font-bold text-primary">Automatic Slippage Tolerance</h6>
				<Switch
					id="auto-slippage"
					checked={slippageTolerance === "AUTO"}
					defaultChecked={slippageTolerance === "AUTO"}
					onCheckedChange={(checked) => {
						setSlippageTolerance(checked ? "AUTO" : "0.1");
					}}
				/>
			</div>
			<p className="text-tertiary">Turn off automatic slippage tolerance to adjust the value</p>
			<section>
				{/* className={cn(slippageTolerance === "AUTO" ? "hidden" : "block")} */}
				<Divider />
				<div className="flex justify-between my-2">
					<div className="inline-flex gap-1 items-center">
						<h6 className="text-sm font-bold text-primary">Slippage tolerance</h6>
						<FullHoverCard
							title="Slippage"
							description="Slippage is the difference between the expected value of output from a trade and the actual
							value due to asset volatility and liquidity depth. If the actual slippage falls outside of the
							user-designated range, the transaction will revert."
							align="end"
						/>
					</div>
					<div className="text-sm">{slippageTolerance === "AUTO" ? "0.1" : slippageTolerance} %</div>
				</div>
				<AnimatePresence>
					{slippageTolerance !== "AUTO" && (
						<motion.div
							key="slippage-tolerance"
							initial={{ opacity: 0, height: 0 }}
							animate={{ opacity: 1, height: "auto" }}
							exit={{ opacity: 0, height: 0 }}
							transition={{ duration: 0.2 }}
							className="flex flex-col gap-2">
							<div className="flex items-center gap-2 w-full">
								<ToggleRoot
									type="single"
									defaultValue="0.5"
									value={slippageTolerance}
									className="flex items-center gap-2">
									{OPTIONS.map((option) => (
										<ToggleItem
											disabled={slippageTolerance === option.value}
											onClick={() => setSlippageTolerance(option.value)}
											className={cn(
												"text-sm  px-2 py-2 whitespace-nowrap data-[state=on]:bg-accent bg-dialog-content",
												slippageTolerance === option.value ? "text-[#FAFAFA]" : ""
											)}
											value={option.value}
											key={option.value}>
											{option.label}
										</ToggleItem>
									))}
								</ToggleRoot>
								<TextField
									type="percent"
									value={slippageTolerance}
									onValueChange={setSlippageTolerance}
									placeholder="Custom"
									id="slippage-tolerance"
									maxDecimals={1}
									unit="%"
								/>
							</div>
							<span className="text-sm text-red-500 mb-2">
								{(+slippageTolerance < 0.1 && +slippageTolerance > 0) || slippageTolerance === ""
									? "Your transaction may be reverted due to low slippage tolerance"
									: isDangerous
									? "Your transaction may be frontrun due to high slippage tolerance"
									: undefined}
							</span>
						</motion.div>
					)}
				</AnimatePresence>
			</section>
		</div>
	);
};
