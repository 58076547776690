import { IconComponent } from "@/ui/types";

export const Sun: IconComponent = (props) => {
	return (
		<svg {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M11 0V1H10V0H11Z" fill="currentColor" />
			<path d="M10 1V2H9V1H10Z" fill="currentColor" />
			<path d="M14 2V3H13V2H14Z" fill="currentColor" />
			<path d="M13 3V4H12V3H13Z" fill="currentColor" />
			<path d="M15 7V6H16V5H15V6H14V7H15Z" fill="currentColor" />
			<path d="M3 4V3H4V4H3Z" fill="currentColor" />
			<path d="M2 2V3H3V2H2Z" fill="currentColor" />
			<path d="M6 2V1H7V2H6Z" fill="currentColor" />
			<path d="M5 0V1H6V0H5Z" fill="currentColor" />
			<path d="M13 12V13H12V12H13Z" fill="currentColor" />
			<path d="M13 13H14V14H13V13Z" fill="currentColor" />
			<path d="M3 12V13H2V14H3V13H4V12H3Z" fill="currentColor" />
			<path d="M1 11V10H2V9H1V10H0V11H1Z" fill="currentColor" />
			<path d="M15 11V10H16V11H15Z" fill="currentColor" />
			<path d="M15 10H14V9H15V10Z" fill="currentColor" />
			<path d="M10 14V15H9V14H10Z" fill="currentColor" />
			<path d="M10 15H11V16H10V15Z" fill="currentColor" />
			<path d="M6 14V15H5V16H6V15H7V14H6Z" fill="currentColor" />
			<path d="M1 5V6H0V5H1Z" fill="currentColor" />
			<path d="M2 7V6H1V7H2Z" fill="currentColor" />
			<path d="M6 3H10V4H6V3Z" fill="currentColor" />
			<path d="M5 5V4H6V5H5Z" fill="currentColor" />
			<path d="M4 6V5H5V6H4Z" fill="currentColor" />
			<path
				d="M4 6H3V10H4V11H5V12H6V13H10V12H11V11H12V10H13V6H12V5H11V4H10V5H11V6H12V10H11V11H10V12H6V11H5V10H4V6Z"
				fill="currentColor"
			/>
		</svg>
	);
};
